import React, { useState, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import axios from "axios"
import {
  TextField,
  Button,
  ThemeProvider,
  createTheme,
  Typography,
  Box,
  useTheme,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import Header from "../../components/Header"
import utill from "lib/utill"
import { useNavigate } from "react-router-dom"
import * as XLSX from "xlsx"
import { format } from "date-fns"
import { config } from "config"

function DBManagement() {
  const navigate = useNavigate()
  const theme = useTheme()
  const [partnerName, setPartnerName] = useState("")
  const [startDate, setStartDate] = useState(() => {
    const today = new Date()
    const oneMonthAgo = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      today.getDate() + 1
    )
    oneMonthAgo.setHours(0, 0, 0, 0) // 시작 시간을 00:00:00으로 설정
    return oneMonthAgo.toISOString().split("T")[0]
  })

  const [endDate, setEndDate] = useState(() => {
    const today = new Date()
    today.setHours(23, 59, 59, 999) // 종료 시간을 23:59:59로 설정
    return today.toISOString().split("T")[0]
  })

  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(false)
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  })

  const [AllParkingLotCount, setAllParkingLotCount] = useState(0)
  const [AllEntranceAndExitCount, setAllEntranceAndExitCount] = useState(0)
  const [AllATypeInsuranceCount, setAllATypeInsuranceCount] = useState(0)
  const [AllBTypeInsuranceCount, setAllBTypeInsuranceCount] = useState(0)
  const [AllReturnCount, setAllReturnCount] = useState(0)
  const [FinalEntranceAndExitCount, setFinalEntranceAndExitCount] = useState(0)
  const [AllInsuranceFee, setAllInsuranceFee] = useState(0)

  const fetchData = async () => {
    // Replace 'your_bearer_token' with the actual bearer token you have
    const bearerToken = utill.base64Decode(
      sessionStorage.getItem("x-a-d-i") ?? ""
    )

    // Configuring headers with bearer token
    const header = {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    }

    // Request body
    const requestBody = {
      pageNo: paginationModel.page,
      CurrentIdx: paginationModel.page,
      PageSize: paginationModel.pageSize,
      StartDate: startDate,
      EndDate: endDate,
      Keyword: partnerName,
    }

    // Fetch data from the API
    setIsLoading(true)
    try {
      const url = `${config.api}/GetDBManagement`
      const response = await axios.post(url, requestBody, header)

      setIsLoading(false)
      setRows(response.data.Results)
      setRowCount(response.data.Total)
      setAllParkingLotCount(response.data.AllParkingLotCount)
      setAllEntranceAndExitCount(response.data.AllEntranceAndExitCount)
      setAllATypeInsuranceCount(response.data.AllATypeInsuranceCount)
      setAllBTypeInsuranceCount(response.data.AllBTypeInsuranceCount)
      setAllInsuranceFee(response.data.AllInsuranceFee)
      setAllReturnCount(response.data.AllReturnCount)
      setFinalEntranceAndExitCount(response.data.FinalEntranceAndExitCount)
    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert("로그인 토큰 만료")
        navigate("/login")
      } else {
        alert("에러가 발생했습니다. 다시 시도해주세요.")
        navigate("/login")
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [paginationModel])

  const columns = [
    { field: "LP", headerName: "차량번호", flex: 0.5 },
    {
      field: "Name",
      headerName: "주차장 명",
      flex: 0.5,
    },
    {
      field: "EnterDateTime",
      headerName: "입차시간",
      flex: 0.5,
    },
    {
      field: "ExitDateTime",
      headerName: "출차시간",
      flex: 0.5,
    },
    {
      field: "ParkingLotAddress",
      headerName: "주차장 주소",
      flex: 1,
    },
    {
      field: "Insurance",
      headerName: "보험종류",
      flex: 0.3,
      renderCell: (params) => {
        if (params.value == "A") {
          return "렌트형"
        } else if (params.value == "B") {
          return "렌트형"
        } else {
          return params.value
        }
      },
    },
    {
      field: "InsuranceFee",
      headerName: "금액",
      flex: 0.2,
      renderCell: (params) => {
        return params.value + "원"
      },
    },
  ]

  const handlePaginationModelChange = (newPaginationModel) => {
    console.log(newPaginationModel)
    setPaginationModel(newPaginationModel)
  }
  const handleNameChange = (event) => {
    setPartnerName(event.target.value)
  }

  const handleStartDateChange = (event) => {
    //setStartDate(event.target.value + " 00:00:00")
    const selectedDate = new Date(event.target.value)
    selectedDate.setUTCHours(0, 0, 0, 0) // 선택한 날짜의 시간을 00:00:00으로 설정
    setStartDate(selectedDate.toISOString().split("T")[0])
  }

  const handleEndDateChange = (event) => {
    //setEndDate(event.target.value + " 23:59:59")
    const selectedDate = new Date(event.target.value)
    selectedDate.setUTCHours(23, 59, 59, 999) // 선택한 날짜의 시간을 23:59:59로 설정
    setEndDate(selectedDate.toISOString().split("T")[0])
  }

  const excelDownload = async () => {
    const bearerToken = utill.base64Decode(
      sessionStorage.getItem("x-a-d-i") ?? ""
    )
    const header = {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    }

    let currentPage = 0
    let allRows = []
    const pageSize = 5000 // 한 번에 가져올 페이지 크기
    try {
      while (true) {
        const requestBody = {
          pageNo: currentPage,
          StartDate: startDate,
          EndDate: endDate,
          Keyword: partnerName,
        }

        const url = `${config.api}/GetDBManagementExcel`
        const response = await axios.post(url, requestBody, header)

        allRows = allRows.concat(response.data.Results)
        currentPage++

        if (currentPage * pageSize >= response.data.Total) {
          break
        }
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status === 403) {
        alert("로그인 토큰 만료")
        navigate("/login")
      } else {
        alert("에러가 발생했습니다. 다시 시도해주세요.")
        navigate("/login")
      }
    }

    // 원하는 필드만 추출하여 새로운 배열 생성
    const filteredRows = allRows.map((row) => {
      const filteredRow = {}
      columns.forEach((column) => {
        if (row[column.field] !== undefined) {
          if (column.field === "Insurance") {
            if (row[column.field] === "A" || row[column.field] === "B") {
              filteredRow[column.headerName] = "렌트형"
            } else {
              filteredRow[column.headerName] = row[column.field]
            }
          } else if (column.field === "InsuranceFee") {
            if (row[column.field] === "500") {
              filteredRow[column.headerName] = "400원"
            } else if (row[column.field] === "400") {
              filteredRow[column.headerName] = "300원"
            } else {
              filteredRow[column.headerName] = row[column.field]
            }
          } else {
            filteredRow[column.headerName] = row[column.field]
          }
        }
      })
      return filteredRow
    })

    // 데이터를 xlsx 시트로 변환
    const ws = XLSX.utils.json_to_sheet(filteredRows)

    // 컬럼에서 헤더 추출
    const headers = columns.map((column) => column.headerName)
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" }) // 헤더를 A1 셀에 추가

    // 워크북 생성 및 시트 추가
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1")

    // 파일명 생성 (현재시간 양식 23.08.10_00:00:00)
    const currentDatetime = new Date()
    const formattedDatetime = format(currentDatetime, "yyMMdd_HHmmss")

    // 파일 다운로드
    XLSX.writeFile(wb, `미팍(${formattedDatetime}).xlsx`)
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="DB손보" subtitle="보험이용현황" />
      <Box>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                현 주차장 수
              </TableCell>
              <TableCell
                sx={{
                  color: theme.palette.secondary[300],
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {AllParkingLotCount.toLocaleString() + " 개"}
              </TableCell>

              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                총 입출차량
              </TableCell>
              <TableCell
                sx={{
                  color: theme.palette.secondary[300],
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {AllEntranceAndExitCount.toLocaleString() + " 대"}
              </TableCell>

              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                총 회차량
              </TableCell>
              <TableCell
                sx={{
                  color: theme.palette.secondary[300],
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {AllReturnCount.toLocaleString() + " 대"}
              </TableCell>

              <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                최종 입출차량
              </TableCell>
              <TableCell
                sx={{
                  color: theme.palette.secondary[300],
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {FinalEntranceAndExitCount.toLocaleString() + " 대"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                기본형 보험건수
              </TableCell>
              <TableCell
                sx={{
                  color: theme.palette.secondary[300],
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {AllATypeInsuranceCount.toLocaleString() + " 대"}
              </TableCell>

              <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                렌트형 보험건수
              </TableCell>
              <TableCell
                sx={{
                  color: theme.palette.secondary[300],
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {AllBTypeInsuranceCount.toLocaleString() + " 대"}
              </TableCell>

              <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}>
                총 보험료
              </TableCell>
              <TableCell
                sx={{
                  color: theme.palette.secondary[300],
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {AllInsuranceFee.toLocaleString() + " 원"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="1rem"
        >
          <Box display="flex" alignItems="center" gap="1rem" mt="20px">
            <TextField
              label="주차장명 or 차량번호"
              value={partnerName}
              onChange={handleNameChange}
              sx={{ width: "200px" }}
            />
            <TextField
              label="시작 날짜"
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              sx={{ width: "200px" }}
            />
            <TextField
              label="끝 날짜"
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              sx={{ width: "200px" }}
            />
            <Button variant="contained" onClick={fetchData}>
              검색하기
            </Button>
            <Button variant="contained" onClick={excelDownload}>
              엑셀추출하기
            </Button>
          </Box>
        </Box>
        <DataGrid
          getRowId={(row) => row.point_oid}
          rows={rows || []}
          columns={columns}
          pageSizeOptions={[pageSize]}
          rowCount={rowCount}
          hideFooterSelectedRowCount={true}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          paginationModel={paginationModel}
          loading={isLoading}
        />
      </Box>
    </Box>
  )
}

export default DBManagement
