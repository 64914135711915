import React from "react"
import GoogleLogin from "@caranmegil/react-google-login"
import utill from "lib/utill"
import { useLocation, useNavigate } from "react-router-dom"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import {
  Avatar,
  Box,
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material"

function Login() {
  const navigate = useNavigate()
  const theme = useTheme()

  const responseGoogle = (response) => {
    if (
      response.profileObj.email == "root@mrpark1.com" ||
      response.profileObj.email == "dbdirect@mrpark1.com" ||
      response.profileObj.email == "didgns10@gmail.com" ||
      response.profileObj.email == "mepark1022@gmail.com"
    ) {
      if (response.tokenId) {
        const idToken = response.tokenId
        const email = response.profileObj.email

        sessionStorage.clear()
        sessionStorage.setItem("x-a-d-i", utill.base64Encode(idToken))
        sessionStorage.setItem("x-a-d-e", utill.base64Encode(email))

        if (
          email == "root@mrpark1.com" ||
          email == "mepark1022@gmail.com" ||
          email == "didgns10@gmail.com"
        ) {
          navigate("/dashboard")
        } else {
          navigate("/dbmanagement")
        }
      } else {
        alert("로그인에 실패했습니다.")
      }
    } else {
      alert("로그인에 실패했습니다.")
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            어드민 로그인
          </Typography>
          <Box noValidate sx={{ mt: 5 }}>
            <GoogleLogin
              clientId={process.env.REACT_APP_CLIENT_ID}
              buttonText="구글 계정 로그인ㅤ"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default Login
