import React, { useEffect, useState } from "react"
import utill from "lib/utill"
import { useLocation, useNavigate } from "react-router-dom"
import { Box } from "@mui/material"
import Header from "components/Header"

const DashBoard = () => {
  const navigate = useNavigate()

  const [gidt, setGidt] = useState("")
  const [email, setEmail] = useState("")

  useEffect(() => {
    const igidt = utill.base64Decode(sessionStorage.getItem("x-a-d-i") ?? "")
    const email = utill.base64Decode(sessionStorage.getItem("x-a-d-e") ?? "")

    setGidt(igidt)
    setEmail(email)
    if (igidt === "" || email === "") {
      navigate("/login")
    }
  }, [gidt, email])

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="대시보드" />
    </Box>
  )
}

export default DashBoard
