import React, { useState, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import axios from "axios"
import {
  TextField,
  Button,
  ThemeProvider,
  createTheme,
  Typography,
  Box,
  useTheme,
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import Header from "../../components/Header"
import utill from "lib/utill"
import { useNavigate } from "react-router-dom"
import { config } from "config"

function Partners() {
  const navigate = useNavigate()
  const theme = useTheme()
  const [partnerName, setPartnerName] = useState("")
  const [partnerBN, setPartnerBN] = useState("")

  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(false)
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  })

  const fetchData = async () => {
    // Replace 'your_bearer_token' with the actual bearer token you have
    const bearerToken = utill.base64Decode(
      sessionStorage.getItem("x-a-d-i") ?? ""
    )

    // Configuring headers with bearer token
    const header = {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    }

    // Request body
    const requestBody = {
      pageNo: paginationModel.page,
      CurrentIdx: paginationModel.page,
      PageSize: 20,
      PartnerName: partnerName,
      partnerBN: partnerBN,
    }

    // Fetch data from the API
    setIsLoading(true)
    try {
      const url = `${config.api}/GetPartners`
      const response = await axios.post(url, requestBody, header)

      setIsLoading(false)
      setRows(response.data.Results)
      setRowCount(response.data.Total)
    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert("로그인 토큰 만료")
        navigate("/login")
      } else {
        alert("에러가 발생했습니다. 다시 시도해주세요.")
        navigate("/login")
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [paginationModel])

  const columns = [
    { field: "PartnerName", headerName: "파트너스 명", flex: 1 },
    { field: "PhoneNumber", headerName: "전화번호", flex: 1 },
    { field: "OwnerEmail", headerName: "E-mail", flex: 1 },
    { field: "PartnerBN", headerName: "사업자 번호", flex: 1 },
    { field: "OwnerName", headerName: "파트너스 대표자 명", flex: 1 },
  ]

  const handlePaginationModelChange = (newPaginationModel) => {
    console.log(newPaginationModel)
    setPaginationModel(newPaginationModel)
  }
  const handleNameChange = (event) => {
    setPartnerName(event.target.value)
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="파트너스" subtitle="파트너스 관리" />
      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="1rem"
        >
          <Box display="flex" alignItems="center" gap="1rem" mt="20px">
            <TextField
              label="파트너스 이름"
              value={partnerName}
              onChange={handleNameChange}
              sx={{ width: "200px" }}
            />
            <Button variant="contained" onClick={fetchData}>
              검색하기
            </Button>
          </Box>
        </Box>
        <DataGrid
          getRowId={(row) => row.PartnerBN}
          rows={rows || []}
          columns={columns}
          pageSizeOptions={[pageSize]}
          rowCount={rowCount}
          hideFooterSelectedRowCount={true}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          paginationModel={paginationModel}
          loading={isLoading}
        />
      </Box>
    </Box>
  )
}

export default Partners
