import { CssBaseline, ThemeProvider } from "@mui/material"
import { createTheme } from "@mui/material/styles"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { themeSettings } from "./theme"
import Layout from "scenes/layout"
import PartnersPayment from "./scenes/partnerspayment"
import Dashboard from "scenes/dashboard"
import DBManagement from "scenes/dbmanagement"
import PartnersPoints from "scenes/partnerspoints"
import Login from "scenes/login"
import Partners from "scenes/partners"

function App() {
  const mode = useSelector((state) => state.global.mode)
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])
  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/partners" element={<Partners />} />
              <Route path="/partnerspayment" element={<PartnersPayment />} />
              <Route path="/partnerspoints" element={<PartnersPoints />} />
              <Route path="/dbmanagement" element={<DBManagement />} />
            </Route>
            <Route path="/login" element={<Login />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
